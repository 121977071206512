<template>
	<v-file-input
		:value="value"
		outlined small-chips show-size
		:accept="accept" :label="label.toUpperCase()"
		clearable color="primary"
		hide-details="auto" placeholder="Select an image file"
		prepend-icon="" :prepend-inner-icon="icon"
		:error-messages="getErrorMessage"
		@change="$emit('input', $event)"
	/>
</template>
<script>
import FormFieldError from "@/mixins/FormFieldError.js";

export default {
	name: "FileField",
	mixins: [FormFieldError],
	props: {
		icon: {
			required: true,
			type: String
		},
		label: {
			required: true,
			type: String
		},
		name: {
			required: true,
			type: String
		},
		value: {
			required: true
		},
		accept: {
			required: true,
			type: String
		}
	},
	emits: ["input"]
}
</script>
